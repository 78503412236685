<div class="control">
  @switch (control.controlType) { @case (ControlType.INPUT) {
  <lib-text-box-control [control]="control"></lib-text-box-control>
  } @case (ControlType.READONLY) {
  <lib-text-box-control
    [control]="control"
    [isReadonly]="true"
  ></lib-text-box-control>
  } @case (ControlType.MULTISELECT) {
  <lib-multi-select-control [control]="control"></lib-multi-select-control>
  } @case(ControlType.DATE) {
  <lib-date-picker-control [control]="control"></lib-date-picker-control>
  } @case (ControlType.DATERANGE) {
  <lib-date-range-control [control]="control"></lib-date-range-control>
  } @case (ControlType.PILL) {
  <lib-pill-button-group [control]="control"> </lib-pill-button-group>
  } @case(ControlType.TOGGLE) {
  <mat-slide-toggle
    [ngClass]="control.formControls[0].formControl.invalid ? 'invalid' : ''"
    [formControl]="control.formControls[0].formControl"
    color="primary"
    ><mat-label>{{ control.label }}</mat-label></mat-slide-toggle
  >
  } @case(ControlType.CHECKBOX) {
  <mat-checkbox [formControl]="control.formControls[0].formControl"
    ><mat-label>{{ control.label }}</mat-label>
  </mat-checkbox>
  } @case(ControlType.INCREMENTING) {
  <section class="incrementing-label">
    <mat-label>{{ control.formControls[0].formControl.value }}</mat-label>
    <input
      matInput
      [formControl]="control.formControls[0].formControl"
      hidden
    />
  </section>
  } @case(ControlType.SELECT) {
  <mat-form-field>
    <mat-label>{{ control.label }}</mat-label>
    <mat-select [formControl]="control.formControls[0].formControl">
      @for (option of control.customOptions?.selectOptions; track option) {
      <mat-option [value]="option.value">{{ option.label }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  } @case(ControlType.LABEL) {
  <mat-label>{{ control.label }}</mat-label>
  } @case(ControlType.ACTION_BUTTON) {
  <mat-label>{{ control.label }}</mat-label>
  <button
    type="button"
    mat-flat-button
    color="primary"
    (click)="control.customOptions.actionButtonHandler()"
  >
    {{ control.customOptions.actionButtonLabel }}
  </button>

  } @default { Control not defined. } } @if(control.tooltip &&
  !control.tooltipHidden){
  <mat-icon [matTooltip]="control.tooltip" color="primary">info</mat-icon>
  } @if(control.errorMessage) {
  <mat-error>{{ control.errorMessage }}</mat-error>
  } @if(control.warningMessage) {
  <mat-error class="warning">{{ control.warningMessage }}</mat-error>
  }
</div>
