import gql from 'graphql-tag';

export const GET_ALL_SUBMISSIONS = gql`
  query GetAllSubmissions($filter: String, $id: String) {
    GetLoggedInUser(Id: $id) {
      FirstName
      LastName
      FacilityAssignments {
        Facility {
          Id
          Name
          KeyChain {
            LegacyId
            OracleId
            SalesforceId
          }
        }
      }
    }
    GetAllEnergyCreditSubmissions(filter: $filter) {
      Items {
        Id
        OrderNumber
        SerialNumber
        FacilityNumber
        CustomerName
        HomeCenterName
        CertificateMailed
        Status
        Version
        SoldDate
        SubmissionCreateDate
      }
      HasNextPage
      HasPreviousPage
      PageNumber
      PageSize
      TotalCount
      TotalPages
      SortDirection
    }
  }
`;

export const GET_SUBMISSION = gql`
  query GetSubmission($id: ID!) {
    GetEnergyCreditSubmission(Id: $id) {
      Id
      FormId
      HomeId
      Home {
        Id
        SoldDate
        ManufactureDate
        HomeModel {
          Id
          BuildType
          SectionCount
          KeyChain {
            LegacyId
          }
        }
        HomeCenter {
          Id
          Name
          KeyChain {
            LegacyId
          }
        }
        Facility {
          Id
          Name
          KeyChain {
            LegacyId
          }
        }
        OrderLine {
          Id
          OrderId
        }
        KeyChain {
          LegacyId
        }
      }
      SubmissionValues {
        Id
        PropertyBindingKey
        Value
      }
    }
  }
`;

export const GET_ORDER = gql`
  query GetOrder($id: ID!) {
    GetOrder(Id: $id) {
      Id
      OrderDate
      KeyChainId
      KeyChain {
        LegacyId
      }
    }
  }
`;