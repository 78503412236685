import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SubmissionsListViewComponent } from '../submissions-list-view/submissions-list-view.component';

import { panelSlider } from 'src/app/shared/content-panels/animations';
import { Pagination } from 'src/app/shared/models';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import {
  AlertActions,
  WebSocketService,
} from 'src/app/shared/services/web-socket.service';
import { SubmissionsService } from '../services/submissions.service';
import { SubmissionView } from '../models/submission-view.model';
import {
  ActiveFiltersComponent,
  FilterService,
} from 'src/app/shared/filtering';
import { SubmissionsDetailViewComponent } from '../submissions-detail-view/submissions-detail-view.component';

@Component({
  selector: 'app-energy-credits-view-container',
  standalone: true,
  imports: [
    NgIf,
    ActiveFiltersComponent,
    SubmissionsListViewComponent,
    SubmissionsDetailViewComponent,
  ],
  templateUrl: './energy-credits-view-container.component.html',
  styleUrl: './energy-credits-view-container.component.scss',
  animations: [panelSlider],
})
export class EnergyCreditsViewContainerComponent implements OnInit, OnDestroy {
  isDetailOpen = false;
  submission: any;

  get materialPageIndex(): number {
    return this._pagination.materialPageIndex;
  }

  private _subscriptions: Subscription[] = [];
  private _pagination: Pagination = {
    paging: true,
    materialPageIndex: 0,
    pageNumber: 1,
    pageSize: 50,
  };

  constructor(
    private filterSvc: FilterService,
    private route: ActivatedRoute,
    private router: Router,
    private submissionsSrv: SubmissionsService,
    private wsService: WebSocketService,
    private spinnerService: SpinnerService
  ) {}

  ngOnDestroy(): void {
    this._subscriptions.forEach((i) => i.unsubscribe());
  }

  ngOnInit(): void {
    this.submissionsSrv.getSubmissions(this._pagination);
    this.filterSvc.initialize('ec_submissions_filters');

    let sub = this.filterSvc.activeFilterChanges$.subscribe((i) => {
      this.submissionsSrv.getSubmissions(
        this.filterSvc.convertToQueryFilter(i)
      );
    });
    this._subscriptions.push(sub);

    sub = this.wsService.connection$?.subscribe((i) => {
      if (i.Type === 'Submission') {
        if (i.Action === AlertActions.PROCESSED) {
          this.spinnerService.hide();
          this.submissionsSrv.getSubmissions(
            this.filterSvc.activeFilters
              ? this.filterSvc.convertToQueryFilter(
                  this.filterSvc.activeFilters
                )
              : this._pagination
          );
        }
        if (AlertActions.isError(i.Action)) {
          this.spinnerService.hide();
        }
      }
    });

    this._subscriptions.push(sub);
  }

  onPageChange(event: PageEvent) {
    this._pagination.materialPageIndex = event.pageIndex; //  Store real page index (0-based) for Material
    this._pagination.pageNumber = event.pageIndex + 1;
    this._pagination.pageSize = event.pageSize; 

    this.submissionsSrv.getSubmissions(this._pagination);
  }

  closeDetail() {
    this.isDetailOpen = false;
    this.submission = null;
    let queryParams: Params = { id: null };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  selectSubmission(submission: SubmissionView): void {
    let queryParams: Params = { id: this.submission?.Id };
    this.submission = submission;
    if (this.submission) {
      this.isDetailOpen = true;
      queryParams = { id: this.submission?.Id };
      this.submissionsSrv.getSubmission(submission.Id);
      
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    } else {
      this.closeDetail();
    }
  }
}
