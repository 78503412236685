import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SubmissionView } from '../models/submission-view.model';
import {
  PanelBodyComponent,
  PanelContainerComponent,
  PanelHeaderComponent,
} from 'src/app/shared/content-panels';
import { MatIconModule } from 'src/app/shared/material';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  DynamicForm,
  DynamicFormGroupComponent,
  FormBuilderService,
  FormControlFactory,
} from 'src/app/shared/forms';
import { ActivatedRoute } from '@angular/router';
import { SubmissionsService } from '../services/submissions.service';
import { forkJoin, of, switchMap } from 'rxjs';
import { Submission } from '../models';
import { HomeCenterType } from 'src/app/shared/models/home-center-type.enum';
import { CustomFormRulesService } from '../services/custom-form-rules.service';
import { SpinnerService } from 'src/app/shared/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-submissions-detail-view',
  standalone: true,
  imports: [
    PanelContainerComponent,
    PanelHeaderComponent,
    PanelBodyComponent,
    ReactiveFormsModule,
    DynamicFormGroupComponent,
    MatIconModule,
  ],
  templateUrl: './submissions-detail-view.component.html',
  styleUrl: './submissions-detail-view.component.scss',
})
export class SubmissionsDetailViewComponent {
  @Input() submissionViewItem: SubmissionView;
  @Output() close = new EventEmitter();

  submission: Submission;
  form: FormGroup;
  dynamicForm: DynamicForm;
  formId: string;

  private _subscriptions: Subscription[] = [];

  constructor(
    public formService: FormBuilderService,
    public subService: SubmissionsService,
    private controlFact: FormControlFactory,
    private formBuilder: FormBuilder,
    private custRulesService: CustomFormRulesService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group([]);

    let sub = this.subService.submission$
      .pipe(
        switchMap((sub) => {
          return forkJoin([
            of(sub),
            this.formService.getForm(sub.FormId.toString()),
          ]);
        })
      )
      .subscribe(([submission, form]) => {
        this.submission = submission;
        if (!this.dynamicForm) {
          this.dynamicForm = form;
          this.dynamicForm.bindControls(this.form);
        }
        let controls = this.dynamicForm.getControls();

        submission.SubmissionValues.forEach((i) => {
          let dynCtrl = controls.find(
            (x) =>
              x.propertyBinding.toLowerCase() ===
              i.PropertyBindingKey.toLowerCase()
          );
          dynCtrl?.setValue(i.Value);
        });

        this.setCustomControls();
        this.form.markAsUntouched();
      });
    this._subscriptions.push(sub);

    sub = this.form.valueChanges.subscribe((i) => {
      this.formService.resetValidations(
        this.dynamicForm.getControls(),
        this.dynamicForm.getGroups()
      );

      this.runRuleSet();
    });

    this._subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.formService.clearRuleHashBlackList();
    this._subscriptions.forEach((i) => i.unsubscribe());
  }

  saveChanges() {
    this.spinnerService.show();
    this.subService.updateSubmission(this.submission, this.form);
    this.form.markAsUntouched(); 
  }

  resetForm() {
    this.form.reset();
    this.formService.clearRuleHashBlackList(); // need to clear this here as well to ensure rules are ran again
    this.subService.getSubmission(this.submissionViewItem.Id);
  }

  private runRuleSet() {
    let controls = this.dynamicForm.getControls();
    let groups = this.dynamicForm.getGroups().filter((i) => i.label);

    let status = this.submission.SubmissionValues.find(
      (i) => i.PropertyBindingKey === 'status'
    ).Value;

    // If the submission is already submitted, then we don't need to run rules over the form anymore.
    // So we lock all the fields and prevent changes.
    if (status === 'Submitted' || status === 'Manually Submitted') {
      controls
        .map((i) => i.formControls[0])
        .forEach((i) => {
          i.formControl.disable({ onlySelf: true });
        });

      return;
    }

    this.formService
      .generateRuleSet(this.dynamicForm.rules, controls)
      .forEach((i) => {
        this.formService.applyRule(i, controls, groups);
      });

    //Custom Rules
    this.formService
      .generateRuleSet(
        this.custRulesService.getCustomRules(this.submission),
        controls
      )
      .forEach((i) => {
        this.formService.applyRule(i, controls, groups);
      });
  }

  closePanel() {
    this.close.emit();
  }

  private setCustomControls(): void {
    let version = this.submission.SubmissionValues.find(
      (i) => i.PropertyBindingKey === 'version'
    ).Value;

    if (
      version === '3' &&
      this.submission.Home.HomeCenter.HomeCenterType === HomeCenterType.Clayton
    ) {
      let group = this.dynamicForm
        .getGroups()
        .find((i) => i.label === 'Heat Pump');
      let ctrl = this.controlFact.createActionButton(
        null,
        'Download Invoice',
        () => {
          console.log('Download invoice would go here.');
        }
      );
      group.addControl(ctrl);
    }
  }
}
