<mat-form-field>
  <mat-label>{{ control.label }}</mat-label>
  <input
    matInput
    [formControl]="inputControl"
    [matDatepicker]="picker"
    [readonly]="control.readonly"
  />
  <mat-hint>MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle
    matIconSuffix
    [for]="picker"
    [disabled]="control.readonly"
  ></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
