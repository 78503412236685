export enum RuleAction {
  MAKE_REQUIRED,
  MAKE_NOT_REQUIRED,
  MAKE_DISABLED,
  MAKE_ENABLED,
  MAKE_HIDDEN,
  MAKE_VISIBLE,
  SET_VALUE,
  INCREMENT_VALUE,
  MAKE_INVALID,
  SET_MIN_VALUE,
  SET_MAX_VALUE,
  SHOW_TOOLTIP,
  HIDE_TOOLTIP,
  SET_TOOLTIP,
  MAKE_READONLY,
  SET_ERROR_MESSAGE,
  SET_WARNING_MESSAGE,
}
