<content-panel>
  <panel-header [heading]="submission?.Home?.KeyChain?.LegacyId">
    @if(form.touched) {
    <button mat-icon-button (click)="resetForm()">
      <mat-icon>delete</mat-icon>
    </button>
    <button mat-icon-button (click)="saveChanges()" [disabled]="form.invalid">
      <mat-icon>save</mat-icon>
    </button>
    } @else {
    <button mat-icon-button (click)="closePanel()">
      <mat-icon>close</mat-icon>
    </button>
    }
  </panel-header>
  <panel-body class="detail-view-container">
    <div class="content">
      <form [formGroup]="form">
        @for(group of dynamicForm?.formGroups; track group){ @if(!group.hidden)
        {
        <lib-dynamic-form-group [group]="group"> </lib-dynamic-form-group>
        } }
      </form>
    </div>
  </panel-body>
</content-panel>
