import { Injectable } from '@angular/core';
import { Submission } from '../models';
import { FormRule, FormRuleFactory, RuleAction } from 'src/app/shared/forms';
import { TokenClaimsService } from 'src/app/shared/services';
import { HomeCenterType } from 'src/app/shared/models/home-center-type.enum';

@Injectable({ providedIn: 'root' })
export class CustomFormRulesService {
  constructor(
    private tokenService: TokenClaimsService,
    private ruleFact: FormRuleFactory
  ) {}

  getCustomRules(submission: Submission): FormRule[] {
    switch (
      submission.SubmissionValues.find(
        (i) => i.PropertyBindingKey === 'version'
      ).Value
    ) {
      case '2':
        return [
          ...this.getCustomVersionTwoRules(submission),
          ...this.getCustomVersionTwoAdminRules(submission),
        ];
      case '3':
        return [
          ...this.getCustomVersionThreeRules(submission),
          ...this.getCustomVersionThreeAdminRules(submission),
        ];
      case 'NEEM 2':
        return [
          ...this.getCustomNeemVersionTwoRules(submission),
          ...this.getCustomNeemVersionTwoAdminRules(submission),
        ];
      case 'NEEM 3':
        return [
          ...this.getCustomNeemVersionThreeRules(submission),
          ...this.getCustomNeemVersionThreeAdminRules(submission),
        ];
      default:
        return [];
    }
  }

  private getCustomVersionTwoRules(submission: Submission): FormRule[] {
    return [];
  }
  private getCustomVersionTwoAdminRules(submission: Submission): FormRule[] {
    return [];
  }


  private getCustomVersionThreeRules(submission: Submission): FormRule[] {
    const zeroEnergyElectric = this.mapBooleanValues(submission.SubmissionValues.find(val => val.PropertyBindingKey === 'zeroEnergyElectric')?.Value);
    const zeroEnergyGas = this.mapBooleanValues(submission.SubmissionValues.find(val => val.PropertyBindingKey === 'zeroEnergyGas')?.Value);
    const isAlternateConstructionSerial = this.isAlternateConstructionSerial(submission.Home.KeyChain.LegacyId);

    return [
      this.ruleFact.createRule(
        "'{isEBuilt}' === 'true' && {pointsTotal} < 10",
        [
          {
            action: RuleAction.SET_VALUE,
            affectingType: 'group',
            applyTo: 'Design Package',
            value:
              'Design Package - Not enough points have been claimed to submit this home.',
          },
          {
            action: RuleAction.MAKE_INVALID,
            affectingType: 'control',
            applyTo: 'pointsTotal',
          },
        ]
      ),
      this.ruleFact.createRule(
        "'{isEBuilt}' === 'false' || ('{isEBuilt}' === 'true' && {pointsTotal} >= 10)",
        [
          {
            action: RuleAction.SET_VALUE,
            affectingType: 'group',
            applyTo: 'Design Package',
            value: 'Design Package',
          },
        ]
      ),
      this.ruleFact.createRule(
        `${submission.Home.HomeCenter.HomeCenterType} === ${HomeCenterType.Independent}`,
        [
          {
            action: RuleAction.MAKE_VISIBLE,
            affectingType: 'control',
            applyTo: 'noInvoiceAvailable',
          },
        ]
      ),
      this.ruleFact.createRule(`'{customerZipCode}' === 'null'`, [
        {
          action: RuleAction.MAKE_ENABLED,
          affectingType: 'control',
          applyTo: 'isEpaRadonZone',
        },
      ]),
      this.ruleFact.createRule(
        `new Date('{soldDate}') < new Date('{manufactureDate}')`,
        [
          {
            action: RuleAction.MAKE_INVALID,
            affectingType: 'control',
            applyTo: 'soldDate',
          },
          {
            action: RuleAction.SET_ERROR_MESSAGE,
            affectingType: 'control',
            applyTo: 'soldDate',
            value: 'Sold date must be greater than the manufacture date.',
          },
        ]
      ),
      this.ruleFact.createRule(
        `new Date('{manufactureDate}') < new Date('2023-01-01')`,
        [
          {
            action: RuleAction.SET_ERROR_MESSAGE,
            affectingType: 'control',
            applyTo: 'manufactureDate',
            value:
              'This home has a manufacture date prior to the ZERH plant certification and therefore does not qualify for Energy Star Version 3 or ZERH. Please submit this home as Energy Star Version 2 if it meets the minimum requirements.',
          },
        ]
      ),
      this.ruleFact.createRule(
        `new Date('{manufactureDate}') > new Date('2023-01-01') && new Date('{manufactureDate}') < new Date('2023-07-10')`,
        [
          {
            action: RuleAction.SET_WARNING_MESSAGE,
            affectingType: 'control',
            applyTo: 'manufactureDate',
            value:
              'Note, this home was manufactured prior to the ZERH go live date. Please confirm that this home was built with all the required additional requirements for EStar Version 3 and ZERH. Additional requirements include but are not limited to, insulation baffles, energy star whole house fan, energy star bath fans, manual S calculations, etc.',
          },
        ]
      ),
      this.ruleFact.createRule(
        `(${zeroEnergyElectric} || ${zeroEnergyGas}) &&
        (${!isAlternateConstructionSerial})`,
        [
          {
            action: RuleAction.MAKE_DISABLED,
            affectingType: 'control',
            applyTo: 'waterHeating',
          },
          {
            action: RuleAction.SET_TOOLTIP,
            affectingType: 'control',
            applyTo: 'waterHeating',
            value:
              'An eBuilt option code is present, but the home does not have the required "AC" at the end of the serial number. Please reach out to home office for assistence.',
          },
        ]
      ),
      this.ruleFact.createSingleExecutionRule(
        `(${zeroEnergyElectric} || ${zeroEnergyGas}) &&
        (${!isAlternateConstructionSerial})`,
        [
          {
            action: RuleAction.SET_VALUE,
            affectingType: 'control',
            applyTo: 'waterHeating',
            value: '',
          },
        ]
      ),
      this.ruleFact.createRule(
        `(${!zeroEnergyElectric} && ${!zeroEnergyGas}) &&
        (${!isAlternateConstructionSerial})`,
        [
          {
            action: RuleAction.MAKE_DISABLED,
            affectingType: 'control',
            applyTo: 'waterHeating',
          },
        ]
      ),
      this.ruleFact.createSingleExecutionRule(
        `(${!zeroEnergyElectric} && ${!zeroEnergyGas}) &&
        (${!isAlternateConstructionSerial})`,
        [
          {
            action: RuleAction.SET_VALUE,
            affectingType: 'control',
            applyTo: 'waterHeating',
            value: 'Omit HP/WH (Standard water heater installed)',
          },
        ]
      ),
      this.ruleFact.createRule(
        `(${zeroEnergyElectric} || ${zeroEnergyGas}) &&
        (${isAlternateConstructionSerial})`,
        [
          {
            action: RuleAction.MAKE_DISABLED,
            affectingType: 'control',
            applyTo: 'waterHeating',
          },
          {
            action: RuleAction.HIDE_TOOLTIP,
            affectingType: 'control',
            applyTo: 'waterHeating',
          },
        ]
      ),
      this.ruleFact.createSingleExecutionRule(
        `(${zeroEnergyElectric} || ${zeroEnergyGas}) &&
        (${isAlternateConstructionSerial})`,
        [
          {
            action: RuleAction.SET_VALUE,
            affectingType: 'control',
            applyTo: 'waterHeating',
            value: 'HP/WH ≥ 3.30 UEF (30 AMP)',
          },
        ]
      ),
      this.ruleFact.createSingleExecutionRule(
        `(${!zeroEnergyElectric} && ${!zeroEnergyGas}) &&
        (${isAlternateConstructionSerial})`,
        [
          {
            action: RuleAction.SET_VALUE,
            affectingType: 'control',
            applyTo: 'waterHeating',
            value: 'Omit HP/WH (Standard water heater installed)',
          },
          {
            action: RuleAction.HIDE_TOOLTIP,
            affectingType: 'control',
            applyTo: 'waterHeating',
          },
        ]
      ),
    ];
  }

  private getCustomVersionThreeAdminRules(submission: Submission): FormRule[] {
    const zeroEnergyElectric = this.mapBooleanValues(submission.SubmissionValues.find(val => val.PropertyBindingKey === 'zeroEnergyElectric')?.Value);
    const zeroEnergyGas = this.mapBooleanValues(submission.SubmissionValues.find(val => val.PropertyBindingKey === 'zeroEnergyGas')?.Value);
    const isAlternateConstructionSerial = this.isAlternateConstructionSerial(submission.Home.KeyChain.LegacyId);

    if (this.tokenService.hasRole('EnergyCreditsAdmin')) {
      return [
        this.ruleFact.createRule(
        `(${zeroEnergyElectric} || ${zeroEnergyGas})`,
          [
            {
              action: RuleAction.MAKE_ENABLED,
              affectingType: 'control',
              applyTo: 'waterHeating',
            },
          ]
        ),
        this.ruleFact.createSingleExecutionRule(
        `(${zeroEnergyElectric} || ${zeroEnergyGas}) &&
        (${isAlternateConstructionSerial})`,
          [
            {
              action: RuleAction.SET_VALUE,
              affectingType: 'control',
              applyTo: 'waterHeating',
              value: 'HP/WH ≥ 3.30 UEF (30 AMP)',
            },
          ]
        ),
        this.ruleFact.createRule(
        `(${!zeroEnergyElectric} && ${!zeroEnergyGas})`,
          [
            {
              action: RuleAction.MAKE_ENABLED,
              affectingType: 'control',
              applyTo: 'waterHeating',
            },
          ]
        ),
        this.ruleFact.createSingleExecutionRule(
        `(${!zeroEnergyElectric} && ${!zeroEnergyGas})`,
          [
            {
              action: RuleAction.SET_VALUE,
              affectingType: 'control',
              applyTo: 'waterHeating',
              value: 'Omit HP/WH (Standard water heater installed)',
            },
          ]
        ),
      ];
    }

    return [];
  }

  private getCustomNeemVersionTwoRules(submission: Submission): FormRule[] {
    return [];
  }
  private getCustomNeemVersionTwoAdminRules(
    submission: Submission
  ): FormRule[] {
    return [];
  }

  private getCustomNeemVersionThreeRules(submission: Submission): FormRule[] {
    return [];
  }
  private getCustomNeemVersionThreeAdminRules(
    submission: Submission
  ): FormRule[] {
    return [];
  }

  private isAlternateConstructionSerial(serialNumber: string): boolean {
    return serialNumber.indexOf('AC') !== -1
      ? true
      : serialNumber.indexOf('AC') !== -1
      ? true
      : false;
  }

  private mapBooleanValues(val: any): boolean {
    return val === 'true'
    ? true
    : val === '' || val === null || val === 'false'
    ? false
    : false;
  }
}