import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable, Subject, switchMap } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { User } from '../../users/models/user';
import { Facility, PaginatedList } from 'src/app/shared/models';
import {
  GET_ALL_SUBMISSIONS,
  GET_ORDER,
  GET_SUBMISSION,
} from 'src/app/shared/graph-queries';
import { Submission, SubmissionValue, SubmissionView } from '../models';
import { WebSocketService } from 'src/app/shared/services/web-socket.service';
import { FormGroup } from '@angular/forms';
import { Order } from 'src/app/shared/models/order.model';

// Used to extract the correct submission data and map to a submissionValue for the form
interface DataMapping {
  key: string;
  getValue: (submissionData: any, orderData: any) => string | Date;
}

const dataToExtract: DataMapping[] = [
  {
    key: 'facility',
    getValue: (submissionData, orderData) => submissionData.Home.Facility.Name + " (" + submissionData.Home.Facility.KeyChain.LegacyId + ")",
  },
  {
    key: 'homeCenter',
    getValue: (submissionData, orderData) => submissionData.Home.HomeCenter.Name + " (" + submissionData.Home.HomeCenter.KeyChain.LegacyId + ")",  
  },
  {
    key: 'soldDate',
    getValue:(submissionData, orderData) => submissionData.Home.SoldDate,
  },
  {
    key: 'serialNumber',
    getValue: (submissionData, orderData) => submissionData.Home.KeyChain.LegacyId,
  },
  {
    key: 'modelNumber',
    getValue: (submissionData, orderData) => submissionData.Home.HomeModel.KeyChain.LegacyId,  
  },
  {
    key: 'homeType',
    getValue: (submissionData, orderData) => submissionData.Home.HomeModel.BuildType,
  },
  {
    key: 'sectionCount',
    getValue: (submissionData, orderData) => submissionData.Home.HomeModel.SectionCount.toString(), 
  },
  {
    key: 'orderNumber',
    getValue: (submissionData, orderData) => orderData.KeyChain.LegacyId  
  },
  {
    key: 'orderDate',
    getValue: (submissionData, orderData) => orderData.OrderDate,
  },
  {
    key: 'manufactureDate',
    getValue: (submissionData, orderData) => submissionData.Home.ManufactureDate,
  }
]

@Injectable({
  providedIn: 'root',
})
export class SubmissionsService {
  submissions$ = new Subject<PaginatedList<SubmissionView>>();
  submission$ = new Subject<Submission>();
  facilities$ = new Subject<Facility[]>();

  constructor(
    private apolloClient: Apollo,
    private authService: MsalService,
    private wsService: WebSocketService,
  ) {}

  getSubmissions(filter?: any): void {
    this.apolloClient
      .query({
        query: GET_ALL_SUBMISSIONS,
        variables: {
          filter: JSON.stringify(filter),
          id: this.authService.instance.getAllAccounts()[0].idTokenClaims.oid,
        },
      })
      .subscribe((response: any) => {
        let result = response.data.GetAllEnergyCreditSubmissions;
        let submissions = new PaginatedList(result);
        
        submissions.Items = submissions.Items.map(
          (x: SubmissionView) => new SubmissionView(x)
        );

        //Available Facilities for filter dropdown.
        let loggedInUser: User = response.data.GetLoggedInUser;
        this.submissions$.next(submissions);
        this.facilities$.next(
          loggedInUser.FacilityAssignments.map((i) => new Facility(i.Facility))
        );
      });
  }

  /*
    Function is used to add anything that needs to be added to the submissionValues. This is needed
    in order to properly populate the form (mainly the home info section)
  */
  processSubmissionData(submissionData: Submission, orderData: Order) {
    const existingValues = new Map(
      submissionData.SubmissionValues.map(value => [value.PropertyBindingKey, value.Value])
    );

    const newValues = dataToExtract
      .filter(mapping => !existingValues.has(mapping.key))
        .map(mapping => ({
            PropertyBindingKey: mapping.key,
            Value: mapping.getValue(submissionData, orderData)
        })
      );

    return [...submissionData.SubmissionValues, ...newValues]
  }

  getSubmission(subId: number): void {
    this.apolloClient
      .query({
        query: GET_SUBMISSION,
        variables: {
          id: subId,
        },
      })
      .pipe(
        switchMap((submission: any) => {
          return this.apolloClient.query ({
            query: GET_ORDER,
            variables: {
              id: submission.data.GetEnergyCreditSubmission.Home.OrderLine.OrderId,
            }
          })
        .pipe(
            map((order: any) => {
              return {
              submission: submission.data.GetEnergyCreditSubmission,
              order: order.data.GetOrder
              }
            })
        )})
      )
      .subscribe((results: any) => {        
        const submissionData = results.submission;
        const orderData = results.order;
        const modifiedSubmissionValues = this.processSubmissionData(submissionData, orderData);
        
        const modifiedSubmission = {
          ...submissionData,
          SubmissionValues: [
            ...modifiedSubmissionValues
          ]
        }
        
        this.submission$.next(modifiedSubmission);
      });
  }

  updateSubmission(submission: Submission, formData: FormGroup): void {

    // Gets the modified submission values from the form
    const submissionValues: SubmissionValue[] = Object.keys(formData.value).map(key => ({
      PropertyBindingKey: key,
      Value: formData.value[key],
    }));

    const soldDateIndex = submissionValues.findIndex(value => value.PropertyBindingKey == 'soldDate');
    const buildTypeIndex = submissionValues.findIndex(value => value.PropertyBindingKey == 'homeType');
    const sectionCountIndex = submissionValues.findIndex(value => value.PropertyBindingKey == 'sectionCount');

    const updatedSubmission = {...submission, 
      Home:{
        ...submission.Home,
        SoldDate: new Date(submissionValues[soldDateIndex].Value),
        HomeModel: {
          ...submission.Home.HomeModel,
          BuildType: submissionValues[buildTypeIndex].Value,
          SectionCount: submissionValues[sectionCountIndex].Value,
        }
      }
    };

    this.wsService.send({
      metadata: {
        eventType: 'UPDATE',
        domain: 'EnergyCreditSubmission',
      },
      data: {
        Id: updatedSubmission.Id,
        FormId: updatedSubmission.FormId,
        HomeId: updatedSubmission.HomeId,
        Home: updatedSubmission.Home,
        SubmissionValues: submissionValues.flatMap((i) => {
          return {
            SubmissionId: updatedSubmission.Id,
            Value: i.Value,
            PropertyBindingKey: i.PropertyBindingKey,
          }
        })
      },
    });
  }
}
