import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatIconModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatLabel,
  MatSelectModule,
  _MatSlideToggleRequiredValidatorModule,
  MatInputModule,
  MatButton,
} from 'src/app/shared/material';
import { PillButtonGroupComponent } from '../pill-button-group-control/pill-button-group.component';
import { ControlType } from '../../models/control-type.enum';
import { DynamicFormControl } from '../../models/dynamic-form-control.model';
import { MultiSelectControlComponent } from '../multi-select-control/multi-select-control.component';
import { TextBoxControlComponent } from '../text-box-control/text-box-control.component';
import { DateRangeControlComponent } from '../date-range-control/date-range-control.component';
import { DatePickerControlComponent } from '../date-picker-control/date-picker-control.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-dynamic-form-control',
  standalone: true,
  imports: [
    CommonModule,
    MatLabel,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    MatButton,
    PillButtonGroupComponent,
    MatSlideToggleModule,
    MatTooltipModule,
    MultiSelectControlComponent,
    TextBoxControlComponent,
    DateRangeControlComponent,
    DatePickerControlComponent,
  ],
  templateUrl: './dynamic-form-control.component.html',
  styleUrl: './dynamic-form-control.component.scss',
})
export class DynamicFormControlComponent {
  @Input()
  control: DynamicFormControl;

  public get ControlType(): typeof ControlType {
    return ControlType;
  }
}
