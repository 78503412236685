import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { GET_FORM_DATA } from 'src/app/shared/graph-queries/form-builder.queries';
import { FormControlFactory } from './form-control-factory.service';
import { FormGroupFactory } from './form-group-factory.service';
import { FormRuleFactory } from './form-rule-factory.service';
import { DynamicForm } from '../models/dynamic-form.model';
import { DynamicFormGroup } from '../models/dynamic-form-group.model';
import { FormRule, FormRuleEffect } from '../models/form-rule.model';
import { DynamicFormControl } from '../models/dynamic-form-control.model';
import { Validators } from '@angular/forms';
import { RuleAction } from '../models/rule-action.enum';
import { ControlType } from '../models/control-type.enum';
import crypto from 'crypto-es';

@Injectable({
  providedIn: 'root',
})
export class FormBuilderService {
  ruleHashBlacklist: Array<string> = [];

  constructor(
    private apolloClient: Apollo,
    private controlFact: FormControlFactory,
    private groupFact: FormGroupFactory,
    private ruleFact: FormRuleFactory
  ) {}

  public getForm(id: string): Observable<DynamicForm> {
    return this.apolloClient
      .query<any>({
        query: GET_FORM_DATA,
        variables: {
          Id: id,
        },
      })
      .pipe(
        map((f) =>
          // This will change once we start properly mapping the data in Dynamo from string to Map type
          // We'll only need to do a single parse after that is changed.
          this.buildDynamicForm(JSON.parse(JSON.parse(f.data.GetForm.Data)))
        )
      );
  }

  public buildDynamicForm(formJson: any): DynamicForm {
    let form = new DynamicForm();
    form.name = formJson.name;
    form.rules = formJson.rules?.map((i: any) =>
      this.ruleFact.createRule(i.condition, i.effects)
    );
    form.formGroups = formJson.formGroups.map((i: any) =>
      this.buildFormGroup(i)
    );

    return form;
  }

  private buildFormGroup(group: any): DynamicFormGroup {
    let dynGroup = this.groupFact.createFormGroup(
      group.label,
      group.layoutDirection,
      group.customOptions
    );

    (<Array<any>>group.children)?.forEach((i) => {
      if (i.type === 'CONTROL') {
        if (i.controlType === ControlType.MULTISELECT) {
          dynGroup.addControl(
            this.controlFact.createMultiSelectBox(
              i.label,
              i.propertyBinding,
              i.defaultValue,
              i.customOptions,
              i.tooltip
            )
          );
        } else if (i.controlType === ControlType.DATERANGE) {
          dynGroup.addControl(
            this.controlFact.createDateRangePicker(
              i.label,
              i.propertyBinding,
              i.defaultValue,
              i.customOptions,
              i.tooltip
            )
          );
        } else
          dynGroup.addControl(
            this.controlFact.create(
              i.controlType,
              i.label,
              i.propertyBinding,
              i.defaultValue,
              i.customOptions,
              i.tooltip
            )
          );
      } else {
        dynGroup.addGroup(this.buildFormGroup(i));
      }
    });

    return dynGroup;
  }

  public resetValidations(
    controls: DynamicFormControl[],
    groups: DynamicFormGroup[]
  ) {
    controls.forEach((ctrl) => {
      if (ctrl.customOptions?.isVisibleByDefault) {
        ctrl.showControl();
      } else {
        ctrl.hideControl();
      }
      if (ctrl.customOptions?.isTooltipVisibleByDefault) {
        ctrl.showTooltip();
      } else {
        ctrl.hideTooltip();
      }

      ctrl.setError('');

      ctrl.formControls
        .map((i) => i.formControl)
        .forEach((c) => {
          // Resetting the default value here, due to incrementing types usually having rules that get ran to recalculate the value.
          if (ctrl.controlType === ControlType.INCREMENTING) {
            c.setValue(ctrl.defaultValue, { onlySelf: true });
          }
          c.clearValidators();
          c.markAsPristine();
          if (c.disabled) {
            c.enable({ onlySelf: true });
          }

          if (ctrl.customOptions.isRequired) {
            c.addValidators(Validators.required);
          }

          if (ctrl.customOptions.isDisabled) {
            c.disable({ onlySelf: true });
          }
        });

      ctrl.markAsPristine();
    });

    groups.forEach((g) => {
      if (g.customOptions?.isVisibleByDefault) {
        g.showGroup();
      } else {
        g.hideGroup();
      }
    });
  }

  public generateRuleSet(
    rules: FormRule[],
    controls: DynamicFormControl[]
  ): FormRule[] {
    let generatedRules = this.ruleFact.copyRules(rules);

    generatedRules.forEach((i) => {
      i.appliedCondition = i.condition;
      this.parseTokensForRule(i).forEach((x) => {
        let control = controls
          .flatMap((i) => i.formControls)
          .find((i) => i.propertyBinding === x)?.formControl;

        let value = control?.value === '' ? null : control?.value;
        i.appliedCondition = i.appliedCondition.replace(`{${x}}`, value);
      });
    });

    return generatedRules;
  }

  private parseTokensForRule(rule: FormRule): string[] {
    let regex = new RegExp('(?:{)([^}]*)(?:})', 'g');
    let match;
    let tokens: string[] = [];
    while ((match = regex.exec(rule.condition))) tokens.push(match[1]);
    return tokens;
  }

  public applyRule(
    rule: FormRule,
    controls?: DynamicFormControl[],
    groups?: DynamicFormGroup[]
  ) {
    // Check for single run rules
    if (!rule.runContinuously) {
      // check blacklist for existing hash
      let hash = this.createHash(
        JSON.stringify({
          condition: rule.condition,
          effects: rule.effects,
        })
      );

      if (this.ruleHashBlacklist.indexOf(hash) === -1) {
        this.ruleHashBlacklist.push(hash);
      } else {
        return;
      }
    }

    if (eval?.(`"use strict";(${rule.appliedCondition})`)) {
      rule.effects.forEach((i) => {
        if (i.affectingType === 'control') {
          this.applyControlRule(
            i,
            controls.find((c) => c.propertyBinding === i.applyTo)
          );
        } else {
          this.applyGroupRule(
            i,
            groups.find((g) => g.name === i.applyTo)
          );
        }
      });
    }
  }

  private applyControlRule(
    effect: FormRuleEffect,
    control: DynamicFormControl
  ) {
    // Iterating through formControls.
    control.formControls.forEach((i) => {
      switch (effect.action) {
        case RuleAction.MAKE_REQUIRED:
          if (
            control.controlType === ControlType.TOGGLE ||
            control.controlType === ControlType.CHECKBOX
          ) {
            i.formControl.addValidators(Validators.requiredTrue);
          } else {
            i.formControl.addValidators(Validators.required);
          }
          i.formControl.markAsTouched();
          control.markAsTouched();
          break;
        case RuleAction.MAKE_NOT_REQUIRED:
          i.formControl.removeValidators(Validators.required);
          i.formControl.markAsTouched();
          control.markAsTouched();
          break;
        case RuleAction.MAKE_DISABLED:
          i.formControl.disable({ onlySelf: true });
          control.markAsTouched();
          break;
        case RuleAction.MAKE_ENABLED:
          i.formControl.enable({ onlySelf: true });
          control.markAsTouched();
          break;
        case RuleAction.SET_VALUE:
          i.formControl.setValue(effect.value, { onlySelf: true });
          control.markAsTouched();
          break;
        case RuleAction.SET_MIN_VALUE:
          i.formControl.addValidators(Validators.min(effect.value));
          control.markAsTouched();
          break;
        case RuleAction.SET_MAX_VALUE:
          i.formControl.addValidators(Validators.max(effect.value));
          control.markAsTouched();
          break;
        case RuleAction.INCREMENT_VALUE:
          i.formControl.setValue(i.formControl.value + effect.value, {
            onlySelf: true,
          });
          control.markAsTouched();
          break;
        case RuleAction.MAKE_HIDDEN:
          control.hideControl();
          control.markAsTouched();
          break;
        case RuleAction.MAKE_VISIBLE:
          control.showControl();
          control.markAsTouched();
          break;
        case RuleAction.MAKE_INVALID:
          i.formControl.setErrors({ invalid: true });
          i.formControl.markAsTouched({ onlySelf: true });
          control.markAsTouched();
          control.color = 'warn';
          break;
        case RuleAction.SET_ERROR_MESSAGE:
          control.setError(effect.value);
          break;
        case RuleAction.SET_WARNING_MESSAGE:
          control.setWarning(effect.value);
          break;
        case RuleAction.MAKE_READONLY:
          i.formControl.enable({ onlySelf: true });
          control.markAsReadOnly();
          break;
        case RuleAction.SHOW_TOOLTIP:
          control.showTooltip();
          break;
        case RuleAction.HIDE_TOOLTIP:
          control.hideTooltip();
          break;
        case RuleAction.SET_TOOLTIP:
          control.setTooltip(effect.value);
          break;
        default:
          break;
      }
    });
  }

  private applyGroupRule(effect: FormRuleEffect, group: DynamicFormGroup) {
    switch (effect.action) {
      case RuleAction.MAKE_VISIBLE:
        group.showGroup();
        break;
      case RuleAction.MAKE_HIDDEN:
        group.hideGroup();
        break;
      case RuleAction.SET_VALUE:
        group.label = effect.value;
        break;
      default:
        break;
    }
  }

  private createHash(inputString: string) {
    return crypto.SHA256(inputString).toString();
  }

  public clearRuleHashBlackList() {
    this.ruleHashBlacklist = []
  }
}
