import { FormControl } from '@angular/forms';
import { ControlType } from './control-type.enum';

//
export class DynamicFormControl {
  controlType?: ControlType;
  customOptions?: any;
  label?: string;
  propertyBinding?: string;
  defaultValue?: any;
  tooltip?: string;
  color?: 'primary' | 'secondary' | 'warn' = 'primary';
  errorMessage?: string;
  warningMessage?: string;

  //Control behavior options
  isRequired?: boolean;
  hidden?: boolean = false;
  touched?: boolean = false;
  readonly?: boolean = false;
  tooltipHidden?: boolean = false;

  formControls?: { propertyBinding?: string; formControl?: FormControl }[];

  markAsTouched() {
    this.touched = true;
  }
  markAsReadOnly() {
    this.readonly = true;
  }
  markAsPristine() {
    this.touched = false;
  }
  hideControl() {
    this.hidden = true;
  }
  showControl() {
    this.hidden = false;
  }

  showTooltip() {
    this.tooltipHidden = false;
  }

  hideTooltip() {
    this.tooltipHidden = true;
  }

  setTooltip(value: string) {
    this.tooltip = value;
  }

  setError(value: string) {
    this.errorMessage = value;
  }

  setWarning(value: string) {
    this.warningMessage = value;
  }

  addFormControl(propertyBinding: string, value?: any) {
    this.formControls = [
      ...this.formControls,
      {
        propertyBinding: propertyBinding,
        formControl: new FormControl(value),
      },
    ];
  }

  /**
   * Sets the value of the control.
   * @param value Value of the control.
   * @param propertyBinding Used to set the value of a control that is not named the same as the dynamic control.
   */
  setValue(value: any, propertyBinding?: string) {
    let ctrl = this.formControls.find(
      (i) =>
        i.propertyBinding.toLowerCase() === this.propertyBinding.toLowerCase()
    );

    if (propertyBinding) {
      ctrl = this.formControls.find(
        (i) => i.propertyBinding === propertyBinding
      );
    }

    switch (this.controlType) {
      case ControlType.DATE:
        ctrl.formControl.setValue(new Date(value));
        break;
      case ControlType.CHECKBOX:
        ctrl.formControl.setValue(
          value === null || value.toLowerCase() === 'false' ? false : true,
          { onlySelf: true }
        );
        break;
      case ControlType.TOGGLE:
        ctrl.formControl.setValue(
          value === null || value.toLowerCase() === 'false' ? false : true,
          { onlySelf: true }
        );
        break;
      case ControlType.INCREMENTING:
        break;
      default:
        ctrl.formControl.setValue(value);
        break;
    }
  }
}
